"use client"
import { useGetQuery } from "@/hooks";
import styles from "./index.module.scss";
import { UseQueryResult } from "@/utils/@tanstack/react-query";
import { useParams, usePathname, useRouter } from "next/navigation";
import { Activity } from "@/utils/constants";
import cn from 'classnames';
import { generateUrl } from "@/utils/common";
import { Locale } from "@/i18n/i18n-config";


export default function Header({ logo }: { logo: string | undefined }) {
    const pathName = usePathname();
    const router = useRouter()
    const params: { activity: string, lang: Locale } = useParams();
    let titleVal = '';

     if ( params.activity === undefined && pathName === '/') {
        titleVal = 'selectActivity'
    } else if (params.activity) {
        titleVal = params.activity;
    } else if (pathName.includes('/performance')) {
        titleVal = 'performance'
    }else if (pathName.includes('/fan/create')) {
        titleVal = 'create'
    } else if (pathName.includes('/fan')) {
        titleVal = 'download'
    } else if(pathName === '/') {
        titleVal = 'enterPin'
    }


    let logoPath = '/greenballLogo.svg';
    if (logo) {
        logoPath = logo;
    }
    return (
        <header className={styles.wrapper} onClick={() => router.push(generateUrl('/', params.lang))}>
            <div className={styles.logo}>
                <img src={logoPath} alt="logo" />
            </div>
            <div className={styles.title} id="titleTop">
                {Activity[titleVal]}
            </div>
            <div className={cn(styles.logo, styles.right)}>
                <img src={logoPath} alt="logo" />
            </div>
        </header>
    );
}
