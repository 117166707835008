import Translate from "@/i18n";

export const Activity: any = {
    dri: <Translate text="driTitle" />,
    pac: <Translate text="pacTitle" />,
    sho: <Translate text="shoTitle" />,
    pass: <Translate text="passTitle" />,
    jum: <Translate text="jumTitle" />,
    pow: <Translate text="powTitle" />,
    overall: <Translate text="overallTitle" />,
    enterPin: <Translate text="enterPin" />,
    selectActivity: <Translate text="selectActivity" />,
    driVal: <Translate text="driVal" />,
    pacVal: <Translate text="pacVal" />,
    shoVal: <Translate text="shoVal" />,
    passVal: <Translate text="passVal" />,
    jumVal: <Translate text="jumVal" />,
    powVal: <Translate text="powVal" />,
    terms: <Translate text="terms" />,
    performance: <Translate text="performance" />,
    download: <Translate text="download" />,
    create: <Translate text="create" />,
}