"use client";

import type { UseQueryResult } from "@/utils/@tanstack/react-query";
import { useQueryClient } from "@/utils/@tanstack/react-query";
import { useParams, usePathname, useRouter, useSearchParams } from "next/navigation";
import React, { FormEvent, useEffect, useRef, useState } from "react";

import { useGetQuery, useInit, useInitHash } from "@/hooks";
import {
  generateUrl,
  getLoggedToken,
  removeUserData,
  setTokens,
  setTokensPublic,
} from "@/utils/common";
import InitInterface from "@/types/initInterface";
import ErrorList from "@/components/common/error/list";
import Loader from "@/components/common/loader";
import styles from "./init.module.css";
import Button from "@/components/common/button/button";
import Translate from "@/i18n";
import Header from "@/components/header";
import Footer from "@/components/footer";
import { Locale } from "@/i18n/i18n-config";

const InitProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [ready, setReady] = useState(false);
  const { data: init } = useGetQuery(["init"]) as UseQueryResult<InitInterface>;
  const { data, mutate, isSuccess, isError, error } = useInit();
  const searchParams = useSearchParams();
  const params = useParams<{ lang: Locale }>()
  const {
    data: datahash,
    mutate: mutateHash,
    isSuccess: isSuccessHash,
    isError: isErrorHash,
  } = useInitHash();
  const queryClient = useQueryClient();
  const hash = getLoggedToken();
  const router = useRouter();
  const path = usePathname();
  const pin = searchParams.get("pin");

  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (pin) {
      mutate({ pathParams: { event_pin: `${pin}` } });
    }
  }, []);

  useEffect(() => {
    if (init) {
      //set vars
      const root = document.documentElement;
      root.style.setProperty('--interaction', init.data.theme.table.text_accent);
      root.style.setProperty('--page-text', init.data.theme.page.text);
      root.style.setProperty('--page-header', init.data.theme.page.header);
      root.style.setProperty('--page-background', init.data.theme.page.background);
      root.style.setProperty('--page-background-image', init.data.theme.page.background_image ? `url(${init.data.theme.page.background_image})` : 'none');
      root.style.setProperty('--table-text', init.data.theme.table.text);
      root.style.setProperty('--table-text-accent', init.data.theme.table.text_accent);
      root.style.setProperty('--table-background', init.data.theme.table.background);
      root.style.setProperty('--badge-text-top', init.data.theme.badge.text_top);
      root.style.setProperty('--badge-text-bottom', init.data.theme.badge.text_bottom);
      setReady(true)
    }
  }, [init]);

  useEffect(() => {
    if (hash !== false && !pin) {
      mutateHash({ pathParams: { event_hash: `${hash}` } });
    } else if (!pin) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    if (isLoading === false && !init) {
      inputRef.current?.focus();
    }
  }, [isLoading]);

  useEffect(() => {
    if (data) {
      queryClient.setQueryData(["init"], data);
      setTokens(data?.data.hash);
      setTokensPublic(data?.data.hash_public);
      router.replace(generateUrl(path, params.lang));
      setIsLoading(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (datahash) {
      queryClient.setQueryData(["init"], datahash);
      setIsLoading(false);
    }
  }, [isSuccessHash]);
  const logoutUser = () => {
    if (!path.includes("se")) {
      router.push(generateUrl("/"));
    }
    queryClient.removeQueries({ queryKey: ["init"], exact: true });
    removeUserData();
  };

  useEffect(() => {
    if (isError || isErrorHash) {
      logoutUser();
    }
  }, [isError, isErrorHash]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    if (inputRef.current?.value) {
      mutate({ pathParams: { event_pin: `${inputRef.current?.value}` } });
    }
  };
  let content: React.ReactNode = null;
  // if ((isLoading || ready === false)) {
  //   // return <main className={styles.main}>
  //   //   <Loader />
  //   // </main>

  // } else
  if (!init) {
    content = (
      <main className={styles.main}>
        <form onSubmit={handleSubmit} className={styles.initForm}>
          <input
            className={styles.input}
            id="number"
            type="text"
            ref={inputRef}
            required
          />
          {isError && (
            <div className={styles.error}>
              <ErrorList error="Pin not found" />
            </div>
          )}
          <Button actionType="submit">
            <Translate text="Enter" />
          </Button>
        </form>
      </main>
    );
  } else {
    content = < main className={styles.main} >
      {children}
    </main >;
  }


  return <><Header logo={init?.data?.theme.page.logo} />{content}<Footer logo={init?.data?.theme.page.logo_bottom}/></>
};

export { InitProvider };
